export const paths = {
    apiBaseUrl: 'http://axa-mqt-api.v2.mmd-technology.com',
    login: 'login',
    manufacturers: '/api/manufacturers',
    occupationClasses: '/api/occupation-classes',
    governorates: '/api/governorates',
    coinsurancePercentages: '/api/coinsurance-percentages',
    models: '/api/models',
    years: '/api/years',
    proposals: '/api/proposals',
    products: '/api/products',
    register: 'register',
    showProposal: '/api/proposals',
    select_quotation: '/api/quotations/select',
    share_quotation: '/api/quotations/share',
    unsubmit_quotation: '/api/quotations/unsubmit',
    reset_password: '/api/reset/password',
    forget_password_reset: '/api/password/reset',
    forget_password: '/api/password/email',
    logout: '/api/logout'
};
